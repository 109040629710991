import React from "react";
import tw from "twin.macro";
import MainFeature1 from "components/features/TwoColWithButton.js";
import { Container } from "components/misc/Layouts.js";
import { SectionHeading } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import aboutBg1 from "images/demo/aboutbg-1.jpg";
import aboutBg2 from "images/demo/aboutbg-2.jpg";
import aboutBg3 from "images/demo/aboutbg-3.jpg";

const Subheading = tw.span`uppercase tracking-wider text-sm text-orange-700`;
// const Subheading2 = tw(SubheadingBase)`mb-4 text-gray-100`;
const Heading = tw(SectionHeading)`w-full text-primary-500`;
const Description2 = tw(SectionDescription)`text-gray-600 max-w-4xl`;
const Headercontainer = tw.div`relative flex items-center flex-col text-center w-full -mb-10`;

export default ({
  heading = "About",
  subheading = "",
  description2 = "iFoodSOS is a platform designed to revolutionize food security by harnessing the power of data and technology. The platform derives its name from “Operating System” since it mimics the computer operating system to deliver a harmonious engagement of actors who leverage data in an intelligent way.",
}) => {
  return (
    <>
      <Headercontainer>
        {/* {subheading && <Subheading2>{subheading}</Subheading2>} */}
        <Heading>{heading}</Heading>
        {description2 && <Description2>{description2}</Description2>}
      </Headercontainer>

      <Container>
        <MainFeature1
          subheading={<Subheading>Our Goal</Subheading>}
          heading={<Heading>Reliable Data-driven platform</Heading>}
          description="Our goal is to leverage the FAIR principles to create a comprehensive data-driven platform that provides a robust and reliable information source for all actors in the food system. With iFoodSOS, we aim to empower stakeholders with the knowledge and resources they need to make informed decisions, to support the growth of sustainable food systems and help feed a growing population."
          imageSrc={aboutBg1}
          buttonRounded={false}
        />
        <MainFeature1
          subheading={<Subheading>Our Mission</Subheading>}
          heading={<Heading>A fairer food system.</Heading>}
          description="Our mission is to create a fairer food system that delivers greater benefits to all actors in the food value chain, from input data for farmers involved in the production, harvesting, post-harvest management including processing, to food processors and processing technologies for nutrition rich provision to researchers, academics, consumers and policymakers."
          buttonRounded={false}
          primaryButtonText={false}
          imageSrc={aboutBg2}
          textOnLeft={false}
        />
        <MainFeature1
          subheading={<Subheading>Our Belief</Subheading>}
          heading={<Heading>Food security lies in the power of data</Heading>}
          description="We believe that the future of food security lies in the power of data, which we are dedicated to deliver through technology and services that bring that future into reality. We believe that the combination of technology and data can drive transformation, create efficiencies and improve lives. We are committed to working tirelessly to make that a reality for everyone involved in the food system."
          imageSrc={aboutBg3}
          buttonRounded={false}
        />
      </Container>
    </>
  );
};
