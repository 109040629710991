import React, { useState } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { ReactComponent as ChevronDownIcon } from "feather-icons/dist/icons/chevron-down.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-7.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-8.svg";

const Subheading = tw(SubheadingBase)`mb-4 text-center text-orange-700`;
const Heading = tw(SectionHeading)`w-full text-primary-500`;
const Description = tw(SectionDescription)`max-w-4xl text-center text-gray-600`;

const Column = tw.div`flex flex-col items-center`;
const HeaderContent = tw.div``;

const FAQSContainer = tw.dl`mt-12 max-w-4xl relative`;
const FAQ = tw.div`cursor-pointer select-none mt-5 px-8 sm:px-10 py-5 sm:py-4 rounded-lg text-gray-800 hover:text-gray-900 bg-gray-200 hover:bg-gray-300 transition duration-300`;
const Question = tw.dt`flex justify-between items-center`;
const QuestionText = tw.span`text-lg lg:text-xl font-semibold`;
const QuestionToggleIcon = motion(styled.span`
  ${tw`ml-2 transition duration-300`}
  svg {
    ${tw`w-6 h-6`}
  }
`);
const Answer = motion(
  tw.dd`pointer-events-none text-sm sm:text-base leading-relaxed`
);

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`absolute top-0 right-0 w-56 h-56 text-teal-400 transform -translate-y-12 pointer-events-none -z-20 opacity-15 translate-x-2/3`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`absolute bottom-0 left-0 w-64 h-64 transform pointer-events-none -z-20 opacity-15 -translate-x-2/3 text-primary-500`}
`;

export default ({
  subheading = "Frequently Asked Questions (FAQs)",
  heading = "You have Questions ?",
  description = "Here, you will find answers to some of the most commonly asked questions about our products and services. Our goal is to provide you with the information you need to make informed decisions and have a smooth experience with us.",
  faqs = [
    {
      question: "What is iFoodSOS ?",
      answer:
        "iFoodSOS is a platform that leverages technology, including Blockchain, to tackle the challenge of food security in Africa. Our goal is to create a sustainable food system in Africa through data-driven solutions.",
    },
    {
      question: "What is the main objective of iFoodSOS ?",
      answer:
        "The main objective of iFoodSOS is to ensure food security in Africa through the use of data and technology. Our platform aims to create a system that benefits all actors involved, from farmers to consumers and provides opportunities for growth and development.",
    },
    {
      question: "What service does iFoodSOS offer ?",
      answer:
        "iFoodSOS offers data collection, data validation, secure data storage, data analysis, and report generation services. Our platform provides a comprehensive solution for food security, allowing stakeholders to make informed decisions based on accurate data.",
    },
    {
      question: "Can I partner with iFoodSOS ?",
      answer:
        "Yes! We are always looking to expand our network and collaborate with like-minded organizations. If you are interested in partnering with us, please reach out to learn more.",
    },
    {
      question:
        "If I contribute data to the platform how do I benefit or generate revenue? ?",
      answer:
        "The iFoodSOS is an API that enables use case contributors to have access to a safe data storage space for their anonymized data for a minimal fee that the government, Konza, and GODAN will keep advising on a continuous basis. It, therefore, is a backend storage space for data that each data use case contributor has access to. Furthermore, it enables a contributor to gain access to other data sets contributed by others. It is a comprehensive data set for an all-around data access space that gives freedom to innovators to access more data paid for using their data contribution. When non-contributors access the data stored in the iFoodSOS, they pay a subscription to access the iFoodSOS API backend for their own innovation. The iFoodSOS will be beneficial to economic planners, governments, development partners, researchers, and academics.",
    },
    {
      question:
        "What makes iFoodSOS different from other food security platforms? ?",
      answer:
        "iFoodSOS is unique in its approach to food security by using blockchain technology to guarantee data immutability and authenticity. Our platform also provides opportunities for growth and development for all actors involved in the food system, setting us apart from other food security platforms.",
    },
    {
      question:
        "Is my data safe and will it be used for the intended purpose? ?",
      answer:
        "While GODAN promotes Findable, accessible, Interoperable, and Re-usable (FAIR) principles that define open data, any organization that wishes to sure their data for whatever reason is introduced to one of the co-creators of the co-creators of the iFoodSOS, the Digital Green. Digital Green, an innovation partner of GODAN, has created the FarmStack as a data access protocol that ensures that data use can be restricted to the purpose intended by the owners. While iFoodSOS strictly promotes FAIR data contribution from its data use case contributors, any concerned contributor to iFoodSOS who wishes to place any restrictions on their data, can have such restrictions integrated into the FarmStack Screening. All data presented to the iFoodSOS is for promoting data-driven research and innovation. The user is left free to apply the data contributed for any beneficial application to mankind. If a user is found to use the data for any other purpose, access to the iFoodSOS shall be reversed and FarmStack use enforced for all data.",
    },
  ],
}) => {
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = (questionIndex) => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  return (
    <Container>
      <ContentWithPaddingXl>
        <Column>
          <HeaderContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
          </HeaderContent>
          <FAQSContainer>
            {faqs.map((faq, index) => (
              <FAQ
                key={index}
                onClick={() => {
                  toggleQuestion(index);
                }}
                className="group"
              >
                <Question>
                  <QuestionText>{faq.question}</QuestionText>
                  <QuestionToggleIcon
                    variants={{
                      collapsed: { rotate: 0 },
                      open: { rotate: -180 },
                    }}
                    initial="collapsed"
                    animate={
                      activeQuestionIndex === index ? "open" : "collapsed"
                    }
                    transition={{
                      duration: 0.02,
                      ease: [0.04, 0.62, 0.23, 0.98],
                    }}
                  >
                    <ChevronDownIcon />
                  </QuestionToggleIcon>
                </Question>
                <Answer
                  variants={{
                    open: { opacity: 1, height: "auto", marginTop: "16px" },
                    collapsed: { opacity: 0, height: 0, marginTop: "0px" },
                  }}
                  initial="collapsed"
                  animate={activeQuestionIndex === index ? "open" : "collapsed"}
                  transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                >
                  {faq.answer}
                </Answer>
              </FAQ>
            ))}
          </FAQSContainer>
        </Column>
      </ContentWithPaddingXl>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
