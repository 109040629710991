import React, { useEffect } from "react";
import { components } from "ComponentRenderer.js";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, Content2Xl } from "components/misc/Layouts";



import Iherosection from "components/ifoodsos/herosesction/herosection.js";
import Ifeaturesection from "components/ifoodsos/featuresection/featuresection.js";
import Ifaqs from "components/ifoodsos/faqsection/faqsection.js"
import Iabout from "components/ifoodsos/aboutsection/aboutsection.js"
import Iwhysection1 from "components/ifoodsos/whysection/whysection1.js"
import Iwhysection2 from "components/ifoodsos/whysection/whysection2.js";
import Ipartners from "components/ifoodsos/partnerssection/partnerssection.js";

export default ({
  features = null,
  landingPages = components.landingPages,
  innerPages = components.innerPages,
  blocks = components.blocks}) => {
  /*
   * Using gtag like this because we only want to use Google Analytics when Main Landing Page is rendered
   * Remove this part and the the gtag script inside public/index.html if you dont need google analytics
   */
  useEffect(() => {
    window.gtag("js", new Date());
    window.gtag("config", "UA-45799926-9");
  }, [])


  const noOfLandingPages = Object.keys(landingPages).length;
  const noOfInnerPages = Object.keys(innerPages).length;
  const noOfComponentBlocks = Object.values(blocks).reduce((acc, block) => acc + Object.keys(block.elements).length, 0);

  features = features || [
    `${noOfLandingPages} Landing Page Demos`,
    `${noOfInnerPages} Inner Pages`,
    `${noOfComponentBlocks} Components`,
    "Uses TailwindCSS",
    "Fully Responsive",
    "Fully Customizable"
  ];

  return (
    <AnimationRevealPage disabled>
      <Container tw="bg-gray-100 -mx-8 -mt-8 pt-8 px-8">
        <Content2Xl>
          <Iherosection />
          <Iabout />
          <Ifeaturesection />
          <Iwhysection1 />
          <Iwhysection2 />
          <Ipartners />
          <Ifaqs />
        </Content2Xl>
      </Container>
    </AnimationRevealPage>
  );
};



