import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg";
import whyBg1 from "images/demo/whybg-1.jpg";
import { SectionDescription } from "components/misc/Typography.js";
const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto relative`;
const TextColumn = styled(Column)((props) => [
  tw`mt-16 md:w-7/12 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`h-full bg-center bg-no-repeat bg-contain rounded`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;
const Subheading = tw(SubheadingBase)`text-center md:text-left text-orange-700`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight text-primary-500 `;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-gray-600`;

const Subheading2 = tw(SubheadingBase)`mb-4 text-gray-100`;

const Description2 = tw(SectionDescription)`text-gray-600 max-w-4xl`;
const Headercontainer = tw.div`relative flex items-center flex-col text-center w-full -mb-10 pt-10`;

const DecoratorBlob = styled(SvgDotPattern)(() => [
  tw`absolute bottom-0 right-0 w-20 h-20 transform translate-x-1/2 translate-y-1/2 fill-current text-primary-500 -z-10`,
]);

export default ({
  subheading = "Accuracy and Trustworthy",
  // heading = <>Accuracy and Trustworthy</>,
  description = "Our platform uses the Blockchain technology to ensure data immutability and authenticity, providing peace of mind to all actors involved in the food system. The iFoodSOS API assures its users that the data they use is accurate and trustworthy used and derived from data use cases that in their capture have promoted innovation at the various levels in the contributing organizations and inspired with good open data principles from Data use Working Group levels.",
  imageSrc = whyBg1,
  imageCss = null,
  imageContainerCss = null,
  imageDecoratorBlob = false,
  imageDecoratorBlobCss = null,
  imageInsideDiv = true,
  textOnLeft = false,
  heading2 = "Why iFoodSOS?",
  description2 = "We believe that the combination of technology and data can drive transformation, create efficiencies and improve lives. We are committed to working tirelessly to make that a reality for everyone involved in the food system.",
}) => {
  return (
    <Container>
      <Headercontainer>
        {subheading && <Subheading2>{subheading}</Subheading2>}
        <Heading>{heading2}</Heading>
        {description2 && <Description2>{description2}</Description2>}
      </Headercontainer>
      <TwoColumn css={!imageInsideDiv && tw`md:items-center`}>
        <ImageColumn css={imageContainerCss}>
          {imageInsideDiv ? (
            <Image imageSrc={imageSrc} css={imageCss} />
          ) : (
            <img src={imageSrc} css={imageCss} alt="" />
          )}
          {imageDecoratorBlob && <DecoratorBlob css={imageDecoratorBlobCss} />}
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            {/* <Heading>{heading}</Heading> */}
            <Description>{description}</Description>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
