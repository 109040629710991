import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import defaultCardImage from "images/shield-icon.svg";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomizeIconImage from "images/customize-icon.svg";

const Container = tw.div`relative bg-primary-900 -mx-8 px-8 text-gray-100`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col flex-wrap items-center max-w-screen-lg py-20 mx-auto md:items-stretch md:flex-row md:justify-center md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4 text-gray-100`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center text-gray-300`;

const VerticalSpacer = tw.div`mt-10 w-full`;

const Column = styled.div`
  ${tw`max-w-xs md:w-1/2 lg:w-1/3`}
`;

const Card = styled.div`
  ${tw`flex flex-col items-center h-full px-2 py-8 mx-4 text-center sm:items-start sm:text-left`}
  .imageContainer {
    ${tw`flex-shrink-0 p-5 text-center bg-gray-100 rounded-full`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`mt-6`}
  }

  .title {
    ${tw`text-xl font-bold leading-none tracking-wider`}
  }

  .description {
    ${tw`mt-2 font-normal leading-snug text-gray-400`}
  }
`;

export default ({
  cards = null,
  heading = "Amazing Features",
  subheading = "",
  description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
}) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const defaultCards = [
    {
      imageSrc: ShieldIconImage,
      title: "Data Capture",
      description:
        "The iFoodSOS API provides a platform for data capture and storage. The API is designed to capture data from various sources and store it in a secure and immutable manner.",
    //   url: "https://timerse.com",
    },
    {
      imageSrc: SupportIconImage,
      title: "Data Validation",
      description:
        "The data captured is subject to a thorough cleaning process, ensuring that it is accurate, complete, and of the highest quality.",
    //   url: "https://google.com",
    },
    {
      imageSrc: CustomizeIconImage,
      title: "Secure Data Storage",
      description:
        "iFoodSOS is equipped with secure storage facilities that guarantee the safety and confidentiality of the data collected.",
    //   url: "https://reddit.com",
    },
    {
      imageSrc: CustomizeIconImage,
      title: "Data Analysis",
      description:
        "iFoodSOS provides stakeholders with the ability to analyze and interpret data in a meaningful and straightforward manner.",
    //   url: "https://reddit.com",
    },
    {
      imageSrc: CustomizeIconImage,
      title: "Reports Generation",
      description:
        "iFoodSOS allows stakeholders to generate reports based on the analyzed data. The reports can be customized to and provide valuable insights into food security trends and patterns.",
    //   url: "https://reddit.com",
    },
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container>
      <ThreeColumnContainer>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
    </Container>
  );
};
