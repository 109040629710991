import React from "react";
import tw from "twin.macro";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import {
  SectionHeading,
} from "components/misc/Headings";
import { SectionDescription } from "components/misc/Typography";

import CANDAI from "images/partnerLogo/CANDAI.png";
import CGIAR from "images/partnerLogo/CGIAR.jpg";
import DigitalGreen from "images/partnerLogo/DigitalGreen.png";
import GIZ from "images/partnerLogo/GIZ.png";
import GODAN from "images/partnerLogo/GODAN.png";
import IFAD from "images/partnerLogo/IFAD.png";
import P4CDA from "images/partnerLogo/P4CDA.png";
import VibraniumID from "images/partnerLogo/VibraniumID.png";
import Ycenter from "images/partnerLogo/Ycenter.jpg";

const HeadingContainer = tw.div``;
const Heading = tw(SectionHeading)`text-primary-500`;
const Description = tw(
  SectionDescription
)`mx-auto text-center max-w-4xl text-gray-600`;

const ImageCard = tw.div`m-6 grid md:grid-cols-3 gap-4 flex justify-center items-center`;
const EachImage = tw.div`mb-4 flex justify-center`;



export default ({
  heading = "Our Partners.",
  description = "iFoodSOS is a co-created API incubated by the Global Open Data for Agriculture and Nutrition (GODAN) initiative and diverse working group partners from producer and value chain organizations, research and innovation, private sector, academia, development, and government.",
  cards = [
    {
      imageSrc: CANDAI,
    },
    {
      imageSrc: GODAN,
    },
    {
      imageSrc: CGIAR,
    },
    {
      imageSrc: DigitalGreen,
    },
    {
      imageSrc: GIZ,
    },
    {
      imageSrc: Ycenter,
    },
    {
      imageSrc: IFAD,
    },
    {
      imageSrc: P4CDA,
    },
    {
      imageSrc: VibraniumID,
    },
  ],
}) => {
  return (
    <Container>
      <ContentWithPaddingXl>
        <HeadingContainer>
          {/* {subheading && <Subheading>{subheading}</Subheading>} */}
          {heading && <Heading>{heading}</Heading>}
          {description && <Description>{description}</Description>}
        </HeadingContainer>
        <HeadingContainer>
          <ImageCard>
            {cards.map((card, index) => (
              <EachImage key={index}>
                <img src={card.imageSrc} style={{
                  width: "192px",
                  height: "auto",
                  borderRadius: "20px"
                }} alt="" />
              </EachImage>
            ))}
          </ImageCard>
        </HeadingContainer>
      </ContentWithPaddingXl>
    </Container>
  );
};
